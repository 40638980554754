'use client';
import { ReactNode, Suspense } from 'react';
import AppContextProvider from '@components/AppContextProvider';
import '../styles/globals.scss';
import '../static/style.css';
import ScriptTagWrapper from './(common-routes)/ScriptTagWrapper';
import { PreloadResources } from './PreloadResources';

interface AppLayoutProps {
  children: ReactNode;
}

export default function RootLayout({ children }: AppLayoutProps) {
  return (
    <html lang="pt-BR">
      <body>
        <Suspense>
          <AppContextProvider>{children}</AppContextProvider>
        </Suspense>
        <ScriptTagWrapper />
        <PreloadResources />
      </body>
    </html>
  );
}
