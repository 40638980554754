'use client';

import { Toaster } from 'react-hot-toast';
import { AppWrapper } from '@common';
import { LACHeader } from '@components/common/LACHeader';

import { UserProvider } from '@contexts/auth';
import { CartProvider } from '@contexts/cart';
import { GTMDataLayerProvider } from '@hooks';
import { CheckoutProvider } from '@contexts/checkout';
import { ZipcodeProvider } from '@contexts/zipcode';
import { RecurrenceProvider } from '@contexts/recurrence';
import { TranslateProvider } from '@contexts/TranslateContext';
import { ApolloWrapper } from '@client/ApolloWrapper';
import { StoreProvider } from '@contexts/store';
import { memo } from 'react';

function AppProvider({ children }: React.PropsWithChildren) {
  return (
    <StoreProvider>
      <ApolloWrapper>
        <GTMDataLayerProvider>
          <ZipcodeProvider>
            <CartProvider>
              <RecurrenceProvider>
                <CheckoutProvider>
                  <TranslateProvider>
                    <UserProvider>
                      <AppWrapper>
                        <LACHeader />

                        {children}
                      </AppWrapper>
                    </UserProvider>
                    <Toaster containerClassName="mt-10" />
                  </TranslateProvider>
                </CheckoutProvider>
              </RecurrenceProvider>
            </CartProvider>
          </ZipcodeProvider>
        </GTMDataLayerProvider>
      </ApolloWrapper>
    </StoreProvider>
  );
}

const component = memo(AppProvider);
component.displayName = 'AppProvider';

export default component;
